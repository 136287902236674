const pt = {
    tspbz: 'colher de chá ',
    dspbz: 'colher de sobremesa ',
    sspbz: 'colher de sopa ',
    teacupbz: 'xícara ',
    cupbz: 'copo de requeijão ',
    tspus: 'colher de chá (EUA)',
    tbspus: 'colher de sopa (EUA',
    cupus: 'copo (EUA)',
    ml: 'mililitro',
    convert: 'Digite o valor:',
    to: 'para',
    converted: 'Valor convertido:',
    chooselang: 'Selecione o idioma'
};

export default pt;